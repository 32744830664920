// src/components/TopCategoriesCarousel.js
import React from 'react';
import { Box, Image, Text, Skeleton, SkeletonText, HStack } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const TopCategoriesCarousel = ({ categories, isLoading }) => {
  return (
    <HStack overflowX="scroll" spacing="24px" padding="4">
      {isLoading ? (
        [...Array(4)].map((_, index) => (
          <Box 
            key={index} 
            borderWidth="1px" 
            borderRadius="lg" 
            overflow="hidden" 
            minWidth="250px" 
            height="100%"
          >
            <Skeleton height="200px" />
            <Box p="6" textAlign="center">
              <SkeletonText mt="4" noOfLines={1} />
            </Box>
          </Box>
        ))
      ) : (
        categories.map(category => (
          <Box 
            key={category.id} 
            borderWidth="1px" 
            borderRadius="lg" 
            overflow="hidden" 
            as={RouterLink} 
            to={`/product-category/${category.slug}`}
            minWidth="250px"
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <Image 
              src={category.image?.src || 'https://via.placeholder.com/300'} 
              alt={category.name} 
              height="200px" 
              objectFit="cover"
            />
            <Box p="6" textAlign="center" flex="1">
              <Text fontWeight="bold" fontSize="xl">{category.name}</Text>
            </Box>
          </Box>
        ))
      )}
    </HStack>
  );
};

export default TopCategoriesCarousel;

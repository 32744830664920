import React from 'react';
import {
  Box,
  Flex,
  Link,
  Skeleton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import useProductCategories from '../hooks/useProductCategories';

const HeaderNavigation = ({ isMobile = false }) => {
  const { categories, isLoading, error } = useProductCategories();

  if (error) {
    console.error('Error fetching categories:', error);
    return <p>Error loading categories.</p>;
  }

  return (
    <Flex
      as="nav"
      direction={isMobile ? 'column' : 'row'}
      bg={isMobile ? 'pink.500' : 'transparent'}
      color={isMobile ? 'white' : 'inherit'}
      padding="10px"
      justifyContent={isMobile ? 'flex-start' : 'space-around'}
    >
      {isLoading ? (
        [...Array(5)].map((_, index) => (
          <Skeleton key={index} height="20px" width="100px" mr="5" />
        ))
      ) : (
        categories.map(category => (
          <Menu key={category.id} isLazy>
            {({ isOpen, onClose }) => (
              <>
                <MenuButton
                  as={Link}
                  to={`/product-category/${category.slug}`}
                  px="3"
                  py="2"
                  borderRadius="md"
                  _hover={{ bg: isMobile ? 'pink.600' : 'transparent' }}
                  bg={isOpen ? 'transparent' : 'inherit'}
                >
                  {category.name}
                </MenuButton>
                {category.children && category.children.length > 0 && (
                  <MenuList bg="pink.500" borderColor="pink.600">
                    {category.children.map(child => (
                      <Menu key={child.id} isLazy>
                        {({ isOpen: isChildOpen }) => (
                          <>
                            <MenuItem
                              as={RouterLink}
                              to={`/product-category/${child.slug}`}
                              _hover={{ bg: 'pink.600' }}
                              bg={isChildOpen ? 'transparent' : 'inherit'}
                              onClick={event => {
                                event.stopPropagation()
                                setTimeout(onClose, 1500)
                              }}
                            >
                              {child.name}
                            </MenuItem>
                            {/* {child.children && child.children.length > 0 && (
                              <MenuList bg="pink.500" borderColor="pink.600">
                                {child.children.map(grandChild => (
                                  <MenuItem
                                    as={RouterLink}
                                    to={`/product-category/${grandChild.slug}`}
                                    key={grandChild.id}
                                    _hover={{ bg: 'pink.600' }}
                                    bg={isChildOpen ? 'transparent' : 'inherit'}
                                  >
                                    {grandChild.name}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            )} */}
                          </>
                        )}
                      </Menu>
                    ))}
                  </MenuList>
                )}
              </>
            )}
          </Menu>
        ))
      )}
    </Flex>
  );
};

export default HeaderNavigation;

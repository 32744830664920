// src/components/Home.js
import React, { useEffect, useState } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import HeroBanner from '../components/HeroBanner';
import FeaturedProductCarousel from '../components/FeaturedProductCarousel';
import TopCategoriesCarousel from '../components/TopCategoriesCarousel';
import useProductCategories from '../hooks/useProductCategories'; // Import the hook
import api from '../woocommerce';
import { Helmet } from 'react-helmet-async';

const Home = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [isLoadingFeaturedProducts, setIsLoadingFeaturedProducts] = useState(true);
  const { categories, isLoading: categoriesLoading, error: categoriesError } = useProductCategories();

  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      setIsLoadingFeaturedProducts(true);
      try {
        // Fetch the featured products
        const response = await api.get('/products', {
          params: {
            featured: true,
            per_page: 4,
            fields: 'id,name,slug,images,price,short_description'
          },
        });

        // Check if there are featured products; if not, fetch the latest 4 products
        if (response.data.length > 0) {
          setFeaturedProducts(response.data);
        } else {
          const latestResponse = await api.get('/products', {
            params: {
              orderby: 'date',
              order: 'desc',
              per_page: 4,
              fields: 'id,name,slug,images,price,short_description'
            },
          });
          setFeaturedProducts(latestResponse.data);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setIsLoadingFeaturedProducts(false);
      }
    };

    fetchFeaturedProducts();
  }, []);

  // Generate JSON-LD for Schema.org
  const generateJsonLd = () => {
    const productsLd = featuredProducts.map(product => ({
      "@type": "Product",
      "name": product.name,
      "image": product.images[0]?.src || '',
      "description": product.short_description,
      "sku": product.sku || '',
      "brand": {
        "@type": "Brand",
        "name": product.attributes.find(attr => attr.slug.toLowerCase() === 'pa_brand')?.options[0] || ''
      },
      "offers": {
        "@type": "Offer",
        "priceCurrency": 'EUR',
        "price": product.price,
        "availability": "https://schema.org/InStock",
        "url": `${window.location.origin}/product/${product.slug}`
      }
    }));

    const categoriesLd = categories.map(category => ({
      "@type": "Category",
      "name": category.name,
      "url": `${window.location.origin}/product-category/${category.slug}`,
      "image": category.image?.src || ''
    }));

    const jsonLd = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Fashion-editor.gr",
      "description": "Welcome to the homepage of Fashion-editor.gr where you can find featured products and top categories.",
      "url": 'https://fashion-editor.gr',
      "potentialAction": {
        "@type": "SearchAction",
        "target": `${window.location.origin}/search?query={search_term_string}`,
        "query-input": "required name=search_term_string"
      },
      "mainEntity": [
        ...productsLd,
        ...categoriesLd
      ]
    };

    return (
      <script type="application/ld+json">
        {JSON.stringify(jsonLd)}
      </script>
    );
  };

  return (
    <Box>
      <Helmet>
        <title>Ανδρικά, Γυναικεία & Παιδικά Ρούχα | Παπούτσια & Αξεσουάρ Online - Fashion-editor.gr</title>
        <meta name="Fashion-editor.gr" />
        <meta
          name="description"
          content="Ανακαλύψτε τις τελευταίες τάσεις σε ανδρικά, γυναικεία και παιδικά ρούχα, παπούτσια και αξεσουάρ. Απολαύστε μοναδικές προσφορές και γρήγορη παράδοση στο Fashion-editor.gr."
        />
      </Helmet>
      {generateJsonLd()}
      <HeroBanner />
      <Heading size="lg" mt="8" mb="4">Featured Products</Heading>
      <FeaturedProductCarousel products={featuredProducts} isLoading={isLoadingFeaturedProducts} />
      <Heading size="lg" mt="8" mb="4">Top Categories</Heading>
      <TopCategoriesCarousel categories={categories} isLoading={categoriesLoading} />
    </Box>
  );
};

export default Home;
// src/components/Footer.js
import React from 'react';
import { Box, Text, Link, Flex } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box bg="pink.500" color="white" py="4" mt="8">
      <Flex justifyContent="center" alignItems="center" direction="column">
        <Text>&copy; {new Date().getFullYear()} Fashion Editor. All rights reserved.</Text>
        <Flex mt="2">
          <Link href="/faq" mx="2">FAQs</Link>
          <Link href="/terms-and-conditions" mx="2">Terms and Conditions</Link> {/* Add link to Terms and Conditions */}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;

// src/components/ProductDetail.js
import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Box, Image, Text, Heading, Stack, SimpleGrid, Button, Flex, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Skeleton, SkeletonText } from '@chakra-ui/react';
import api from '../woocommerce';
import { Helmet } from 'react-helmet-async';

const ProductDetail = () => {
  const { slug } = useParams();
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [fallbackProducts, setFallbackProducts] = useState([]);
  const [breadcrumbCategories, setBreadcrumbCategories] = useState([]);
  const [isLoadingProduct, setIsLoadingProduct] = useState(true);
  const [isLoadingBreadcrumb, setIsLoadingBreadcrumb] = useState(true);

  useEffect(() => {
    api.get(`/products?slug=${slug}`)
      .then(response => {
        const productData = response.data[0];
        setProduct(productData);

        if (productData.categories.length > 0) {
          buildCategoryTree(productData.categories[0]);
        }

        if (productData.related_ids.length > 0) {
          api.get(`/products?include=${productData.related_ids.join(',')}&per_page=4&_fields=id,name,slug,images,price_html`)
            .then(response => {
              setRelatedProducts(response.data);
            })
            .catch(error => {
              console.error('Error fetching related products', error);
            });
        } else {
          fetchFallbackProducts(productData.categories[0].id);
        }
      })
      .catch(error => {
        console.error('Error fetching product', error);
      })
      .finally(() => {
        setIsLoadingProduct(false);
      });
  }, [slug]);

  const buildCategoryTree = async (category) => {
    const categories = [];
    let currentCategory = category;
    let hasParent = true;

    const response = await api.get(`/products/categories/${currentCategory.id}`);
    currentCategory = response.data;
    categories.unshift(currentCategory);

    if(currentCategory.parent !== 0){
      while(hasParent === true){
        const response = await api.get(`/products/categories/${currentCategory.parent}`);
        currentCategory = response.data;
        categories.unshift(currentCategory);

        if(currentCategory.parent !== 0){
          hasParent = true;
        } else {
          hasParent = false;
        }
      }
    }

    setBreadcrumbCategories(categories);
    setIsLoadingBreadcrumb(false);
  };

  const fetchFallbackProducts = (categoryId) => {
    api.get('/products', {
      params: {
        category: categoryId,
        per_page: 4,
        _fields: 'id,name,slug,images,price_html'
      }
    })
    .then(response => {
      setFallbackProducts(response.data);
    })
    .catch(error => {
      console.error('Error fetching fallback products', error);
    });
  };

  const generateJsonLd = () => {
    if (!product) return null;
  
    const imageUrl = product.images && product.images[0] ? product.images[0].src : '';
    const brandName = product.attributes?.find(attr => attr.name.toLowerCase() === 'brand')?.options[0] || '';
    const priceCurrency = 'EUR';
  
    const jsonLd = {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": product.name || '',
      "image": imageUrl,
      "description": product.short_description || '',
      "sku": product.sku || '',
      "brand": {
        "@type": "Brand",
        "name": brandName,
      },
      "offers": {
        "@type": "Offer",
        "url": window.location.href,
        "priceCurrency": priceCurrency,
        "price": product.price || '',
        "itemCondition": "https://schema.org/NewCondition",
        "availability": "https://schema.org/InStock",
        "seller": {
          "@type": "Organization",
          "name": "Fashion-editor.gr"
        }
      }
    };
  
    return (
      <script type="application/ld+json">
        {JSON.stringify(jsonLd)}
      </script>
    );
  };

  return (
    <Box p="6" maxW="1320px" mx="auto">
      {/* Breadcrumb */}
      {isLoadingBreadcrumb ? (
        <Skeleton height="20px" mb="6" />
      ) : (
        <Box
          mb="6"
          overflowX="auto" // Enable horizontal scrolling
          whiteSpace="nowrap" // Prevent items from wrapping
        >
          <Breadcrumb display="inline-block">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/">Αρχική</BreadcrumbLink>
            </BreadcrumbItem>
            {breadcrumbCategories.map(category => (
              <BreadcrumbItem key={category.id}>
                <BreadcrumbLink as={RouterLink} to={`/product-category/${category.slug}`}>
                  {category.name}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ))}
            {product && (
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink>{product.name}</BreadcrumbLink>
              </BreadcrumbItem>
            )}
          </Breadcrumb>
        </Box>
      )}

      {/* Main Product Info */}
      <Flex direction={['column', 'row']} gap={6}>
        <Box flex="1">
          {isLoadingProduct ? (
            <Skeleton height="300px" />
          ) : (
            <Image src={product.images[0]?.src} alt={product.name} />
          )}
        </Box>
        <Box flex="2">
          <Stack spacing="4">
            {isLoadingProduct ? (
              <>
                <Skeleton height="30px" />
                <SkeletonText mt="4" noOfLines={4} spacing="4" />
                <Skeleton height="20px" width="100px" />
              </>
            ) : (
              <>
                <Heading>{product.name}</Heading>
                <Text dangerouslySetInnerHTML={{ __html: product.price_html }} />
                {product.meta_data.length > 0 && (
                  <Box>
                    <Stack spacing="2">
                      {product.meta_data.map(meta => (
                        <Text key={meta.id}><strong>{meta.key === 'availability' ? 'Διαθεσιμότητα' : meta.key}:</strong> {meta.value}</Text>
                      ))}
                    </Stack>
                  </Box>
                )}
                <Text dangerouslySetInnerHTML={{ __html: product.short_description }} />
                {product.external_url && (
                  <Button as="a" href={product.external_url} target="_blank" colorScheme="pink">
                    {product.button_text || 'Buy Now'}
                  </Button>
                )}

                {/* Additional Information Section */}
                <Box mt="8">
                  <Heading size="md" mb="4">Επιπλεόν Χαρακτηριστικά</Heading>
                  <Stack spacing="4">
                    <Text><strong>SKU:</strong> {product.sku || 'N/A'}</Text>
                    {product.attributes.length > 0 && (
                      <Box>
                        <Stack spacing="2">
                          {product.attributes.map(attr => (
                            <Text key={attr.id}><strong>{attr.name === 'Brand' ? 'Μάρκα' : attr.name}:</strong> {attr.options.join(', ')}</Text>
                          ))}
                        </Stack>
                      </Box>
                    )}
                  </Stack>
                </Box>
              </>
            )}
          </Stack>
        </Box>
      </Flex>

      {/* Related Products Section */}
      {isLoadingProduct ? (
        <Box mt="10">
          <Skeleton height="30px" width="200px" mb="6" />
          <SimpleGrid columns={[1, 2, 3, 4]} spacing="40px">
            {[...Array(4)].map((_, index) => (
              <Box key={index} borderWidth="1px" borderRadius="lg" overflow="hidden">
                <Skeleton height="200px" />
                <Box p="6">
                  <SkeletonText mt="4" noOfLines={2} spacing="4" />
                  <Skeleton height="40px" width="100px" mt="4" />
                </Box>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      ) : (
        <>
          {product.related_ids.length > 0 ? (
            <Box mt="10">
              <Heading size="lg" mb="4">Δες Επίσης</Heading>
              <SimpleGrid columns={[1, 2, 3, 4]} spacing="12px">
                {relatedProducts.map(relatedProduct => (
                  <Box key={relatedProduct.id} borderWidth="1px" borderRadius="lg" overflow="hidden">
                    <RouterLink to={`/product/${relatedProduct.slug}`}>
                      <Image src={relatedProduct.images[0]?.src} alt={relatedProduct.name} />
                    </RouterLink>
                    
                    <Box p="6">
                      <Stack spacing="3">
                        <Text fontWeight="bold" fontSize="xl">{relatedProduct.name}</Text>
                        <Text dangerouslySetInnerHTML={{ __html: relatedProduct.price_html }} />
                        <Button as={RouterLink} to={`/product/${relatedProduct.slug}`} colorScheme="pink">
                          Περισσότερα
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                ))}
              </SimpleGrid>
            </Box>
          ) : (
            <Box mt="10">
              <Heading size="lg" mb="4">Δείτε Επίσης στην ίδια κατηγορία</Heading>
              <SimpleGrid columns={[1, 2, 3, 4]} spacing="12px">
                {fallbackProducts.map(product => (
                  <Box key={product.id} borderWidth="1px" borderRadius="lg" overflow="hidden">
                    <RouterLink to={`/product/${product.slug}`}>
                      <Image src={product.images[0]?.src} alt={product.name} />
                    </RouterLink>
                    <Box p="6">
                      <Stack spacing="3">
                        <Text fontWeight="bold" fontSize="xl">{product.name}</Text>
                        <Text dangerouslySetInnerHTML={{ __html: product.price_html }} />
                        <Button as={RouterLink} to={`/product/${product.slug}`} colorScheme="pink">
                          Περισσότερα
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                ))}
              </SimpleGrid>
            </Box>
          )}
        </>
      )}
      {generateJsonLd()}
      <Helmet>
        <title>{product ? `${product.name} - Fashion-editor.gr` : 'Loading... - Fashion-editor.gr'}</title>
        <meta name="description" content={product ? `Απόκτησε τώρα ${product.name} online από το Fashion-editor.gr.`: 'Loading...'} />
      </Helmet>
    </Box>
  );
};

export default ProductDetail;
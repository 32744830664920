import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  Button,
  Image,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Skeleton,
  SkeletonText,
  Heading,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  DrawerFooter,
  useDisclosure,
  ButtonGroup,
  Link as ChakraLink
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useParams, useSearchParams, Link as RouterLink } from 'react-router-dom';
import api from '../woocommerce';
import Pagination from '../components/Pagination';
import Filters from '../components/Filters';
import { Helmet } from 'react-helmet-async';

const CategoryPage = () => {
  const { slug } = useParams();
  const [categoryId, setCategoryId] = useState(null);
  const [category, setCategory] = useState(null);
  const [breadcrumbCategories, setBreadcrumbCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [isLoadingFilters, setIsLoadingFilters] = useState(true);
  const [filters, setFilters] = useState({
    minPrice: '',
    maxPrice: '',
    brands: [],
    sizes: [],
    colors: []
  });
  const [pagination, setPagination] = useState({ currentPage: 1, totalPages: 1 });
  const [searchParams, setSearchParams] = useSearchParams();
  const [availableBrands, setAvailableBrands] = useState([]);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);
  const [priceRange, setPriceRange] = useState({ min: 0, max: null });
  const [isLoadingBreadcrumb, setIsLoadingBreadcrumb] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    api.get('/products/categories', { params: { slug, _fields: 'id,name,slug,parent' } })
      .then(response => {
        const categoryData = response.data[0];
        setCategoryId(categoryData.id);
        setCategory(categoryData);
        buildCategoryTree(categoryData);
      })
      .catch(error => {
        console.error('Error fetching category', error);
      });
  }, [slug]);

  useEffect(() => {
    if (categoryId) {
      applySearchParamsToFilters();
      fetchProducts().then(() => fetchAttributesAndTerms());
    }
  }, [categoryId, searchParams]);

  const buildCategoryTree = async (category) => {
    const categories = [];
    let currentCategory = category;

    while (currentCategory) {
      categories.unshift(currentCategory);
      if (currentCategory.parent && currentCategory.parent !== 0) {
        try {
          const response = await api.get(`/products/categories/${currentCategory.parent}`, {
            params: { _fields: 'id,name,slug,parent' }
          });
          currentCategory = response.data;
        } catch (error) {
          console.error('Error fetching parent category', error);
          break;
        }
      } else {
        currentCategory = null;
      }
    }

    setBreadcrumbCategories(categories);
    setIsLoadingBreadcrumb(false);
  };

  const applySearchParamsToFilters = () => {
    const newFilters = { ...filters };

    newFilters.minPrice = searchParams.get('minPrice') || '';
    newFilters.maxPrice = searchParams.get('maxPrice') || '';

    if (searchParams.getAll('brands').length > 0) {
        newFilters.brands = searchParams.getAll('brands');
    }

    if (searchParams.getAll('sizes').length > 0) {
        newFilters.sizes = searchParams.getAll('sizes');
    }

    if (searchParams.getAll('colors').length > 0) {
        newFilters.colors = searchParams.getAll('colors');
    }

    setFilters(newFilters);
    setPagination({ ...pagination, currentPage: parseInt(searchParams.get('page') || 1, 10) });
};


  const fetchProducts = async () => {
    setIsLoadingProducts(true);
    setIsLoadingFilters(true);
    try {
        const paramsForCurrentPage = {
            category: categoryId,
            page: pagination.currentPage,
            per_page: 9,
            min_price: filters.minPrice || undefined,
            max_price: filters.maxPrice || undefined,
            _fields: 'id,name,slug,images,price,price_html,categories,attributes',
        };

        // Filter by brands
        if (filters.brands.length > 0) {
            paramsForCurrentPage['attribute'] = 'pa_brand';
            paramsForCurrentPage['attribute_term'] = filters.brands.join(',');
        }

        // Filter by sizes
        if (filters.sizes.length > 0) {
            paramsForCurrentPage['attribute'] = 'pa_size';
            paramsForCurrentPage['attribute_term'] = filters.sizes.join(',');
        }

        // Filter by colors
        if (filters.colors.length > 0) {
            paramsForCurrentPage['attribute'] = 'pa_color';
            paramsForCurrentPage['attribute_term'] = filters.colors.join(',');
        }

        const response = await api.get('/products', { params: paramsForCurrentPage });
        const productsData = response.data;

        // Set the current page products
        setProducts(productsData);

        // Update pagination info
        setPagination({
            currentPage: pagination.currentPage,
            totalPages: response.headers['x-wp-totalpages'],
        });

        // Fetch all products for min/max price calculation
        await fetchAllProductsForFilters();

    } catch (error) {
        console.error('Error fetching products:', error);
    } finally {
        setIsLoadingProducts(false);
    }
};

  // Function to fetch all products to get min/max prices for filters
  const fetchAllProductsForFilters = async () => {
    try {
        const paramsForAllProducts = {
            category_id: categoryId,
        };

        const response = await api.get('/custom-api/v1/products', { params: paramsForAllProducts });
        const products = response.data;

        setAllProducts(products);

        // Ensure that products have a price field and filter out undefined/null prices
        const validPrices = products.map(product => parseFloat(product.price)).filter(price => !isNaN(price));

        if (validPrices.length > 0) {
            const minPrice = Math.min(...validPrices);
            const maxPrice = Math.max(...validPrices);

            // Update filters with min and max prices
            setPriceRange({ min: minPrice, max: maxPrice });

            setFilters(prevFilters => ({
                ...prevFilters,
                minPrice: filters.minPrice || minPrice,
                maxPrice: filters.maxPrice || maxPrice,
            }));
        }

    } catch (error) {
        console.error('Error fetching all products for filters:', error);
    }
};

  

  const fetchAttributesAndTerms = async () => {
    setIsLoadingFilters(true);
    try {
      
      const brands = [];
      const brandresponse = await api.get('/custom-api/v1/attribute-terms/brand');
      brands.push(...brandresponse.data);
      setAvailableBrands(brands);

      const sizes = [];
      const sizeresponse = await api.get('/custom-api/v1/attribute-terms/size');
      sizes.push(...sizeresponse.data);
      setAvailableSizes(sizes);

      const colors = [];
      const colorresponse = await api.get('/custom-api/v1/attribute-terms/color');
      colors.push(...colorresponse.data);
      setAvailableColors(colors);

    } catch (error) {
      console.error('Error fetching attributes and terms', error);
    } finally {
      setIsLoadingFilters(false);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value, checked } = e.target;
    const newFilters = { ...filters };

    if (name === 'minPrice' || name === 'maxPrice') {
        newFilters[name] = value; // Update min/max price filter
    } else {
        // Handle selection of brands, sizes, or colors
        if (checked) {
            newFilters[name] = [...newFilters[name], value];
        } else {
            newFilters[name] = newFilters[name].filter(item => item !== value);
        }
    }

    // Update filters and the URL search parameters
    setFilters(newFilters);
    updateSearchParams(newFilters);
};


  const handlePageChange = (newPage) => {
    setPagination(prev => ({ ...prev, currentPage: newPage }));
    updateSearchParams(filters, newPage);
  };

  const updateSearchParams = (newFilters, newPage = pagination.currentPage) => {
    const params = new URLSearchParams();

    if (newFilters.minPrice) params.set('minPrice', newFilters.minPrice);
    if (newFilters.maxPrice) params.set('maxPrice', newFilters.maxPrice);
    newFilters.brands.forEach(brand => params.append('brands', brand));
    newFilters.sizes.forEach(size => params.append('sizes', size));
    newFilters.colors.forEach(color => params.append('colors', color));

    params.set('page', newPage);
    setSearchParams(params);
  };

  const generateBreadcrumbJsonLd = () => {
    const itemListElement = breadcrumbCategories.map((category, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "name": category.name,
      "item": `${window.location.origin}/product-category/${category.slug}`
    }));

    return (
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": itemListElement
        })}
      </script>
    );
  };

  const generateProductListJsonLd = () => {
    const itemListElement = products.map((product, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": `${window.location.origin}/product/${product.slug}`
    }));

    return (
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "ItemList",
          "itemListElement": itemListElement
        })}
      </script>
    );
  };

  return (
    <Box p="6" maxW="1320px" mx="auto">
      <Helmet>
        <title>{category ? `${category.name} - Fashion-editor.gr` : 'Loading... - Fashion-editor.gr'}</title>
        <meta name="description" content={category ? `Ανακαλύψτε τις τελευταίες τάσεις σε ${category.name}.` : 'Loading...'} />
      </Helmet>
      {generateBreadcrumbJsonLd()}
      {generateProductListJsonLd()}

      {isLoadingBreadcrumb ? (
        <Skeleton height="20px" mb="6" />
      ) : (
        <Box overflowX="auto" whiteSpace="nowrap" mb="6">
          <Breadcrumb display="inline-block">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/">Αρχική</BreadcrumbLink>
            </BreadcrumbItem>
            {breadcrumbCategories.map(category => (
              <BreadcrumbItem key={category.id}>
                <BreadcrumbLink as={RouterLink} to={`/product-category/${category.slug}`}>
                  {category.name}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </Box>
      )}

      <Heading as="h1" size="xl" mb="5">
        {category ? category.name : <Skeleton height="30px" width="200px" />}
      </Heading>

      <ButtonGroup size='sm' isAttached variant='outline' display={{ base: 'block', md: 'none' }}>
        <Button onClick={onOpen}>Filters</Button>
        <IconButton
          aria-label="Filter"
          icon={<HamburgerIcon />}
          onClick={onOpen}
          mb="4"
          mt="4"
        />
      </ButtonGroup>

      <Flex direction={['column', 'row']} gap={6}>
        <Box flex="1" display={{ base: 'none', md: 'block' }}>
          {isLoadingFilters ? (
            <Stack spacing={4}>
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          ) : (
            <Filters
              displayedProducts={allProducts}
              availableBrands={availableBrands}
              availableSizes={availableSizes}
              availableColors={availableColors}
              selectedFilters={filters}
              handleFilterChange={handleFilterChange}
              isLoading={isLoadingFilters}
            />
          )}
        </Box>

        <Box flex="3">
          {isLoadingProducts ? (
            <SimpleGrid columns={[1, 2, 3]} spacing="6">
              {[...Array(6)].map((_, index) => (
                <Box key={index} borderWidth="1px" borderRadius="lg" overflow="hidden">
                  <Skeleton height="200px" />
                  <Box p="6">
                    <SkeletonText mt="4" noOfLines={4} spacing="4" />
                  </Box>
                </Box>
              ))}
            </SimpleGrid>
          ) : (
            <>
              <SimpleGrid columns={[1, 2, 3]} spacing="6">
                {products.map(product => (
                  <Box key={product.id} borderWidth="1px" borderRadius="lg" overflow="hidden">
                    <ChakraLink as={RouterLink} to={`/product/${product.slug}`} flex="1">
                      <Image 
                        src={product.images[0]?.src} 
                        alt={product.name} 
                        width="100%" 
                        height="auto" 
                        objectFit="cover"
                        loading="lazy"
                        _hover={{ transform: 'scale(1.05)', transition: 'transform 0.3s' }}
                      />
                    </ChakraLink>
                    <Box p="6">
                      <Stack spacing="3">
                        <Text fontWeight="bold" fontSize="xl">{product.name}</Text>
                        <Text dangerouslySetInnerHTML={{ __html: product.price_html }} />
                        <Button as={RouterLink} to={`/product/${product.slug}`} colorScheme="pink">
                          Περισσότερα
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                ))}
              </SimpleGrid>

              {pagination.totalPages > 1 && (
                <Pagination
                  currentPage={pagination.currentPage}
                  totalPages={pagination.totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </>
          )}
        </Box>
      </Flex>

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='1px'>
            Φίλτρα
          </DrawerHeader>
          <DrawerBody>
            <Filters
              displayedProducts={allProducts}
              availableBrands={availableBrands}
              availableSizes={availableSizes}
              availableColors={availableColors}
              selectedFilters={filters}
              handleFilterChange={handleFilterChange}
              isLoading={isLoadingFilters}
            />
          </DrawerBody>
          <DrawerFooter borderTopWidth='1px'>
            <Button variant='outline' mr={3} onClick={onClose}>
              Show Products
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default CategoryPage;
// src/hooks/useProductCategories.js
import { useState, useEffect } from 'react';
import api from '../woocommerce';

const useProductCategories = (cacheTime = 3600000) => { // cacheTime in milliseconds, default is 1 hour - 3600000
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async (parent = 0, level = 1) => {
      if (level > 2) return []; // Stop fetching if we exceed level 3

      try {
        setIsLoading(true);

        // Check if categories are already cached
        const cachedData = localStorage.getItem(`productCategoriesLevel${level}_${parent}`);
        const cachedTime = localStorage.getItem(`productCategoriesCacheTime${level}_${parent}`);

        if (cachedData && cachedTime && (Date.now() - cachedTime < cacheTime)) {
          return JSON.parse(cachedData);
        }

        // Fetch categories from WooCommerce API
        const response = await api.get('/products/categories', {
          params: {
            per_page: 100,
            hide_empty: true,
            parent,
            _fields: 'id,name,slug,parent,image' // Only fetch necessary fields
          }
        });

        const categories = response.data;

        // Fetch child categories recursively
        const childCategoryPromises = categories.map(category =>
          fetchCategories(category.id, level + 1)
        );
        const children = await Promise.all(childCategoryPromises);

        const categoriesWithChildren = categories.map((category, index) => ({
          ...category,
          children: children[index] || []
        }));

        // Cache the fetched categories and the current time
        localStorage.setItem(
          `productCategoriesLevel${level}_${parent}`,
          JSON.stringify(categoriesWithChildren)
        );
        localStorage.setItem(
          `productCategoriesCacheTime${level}_${parent}`,
          Date.now().toString()
        );

        return categoriesWithChildren;
      } catch (err) {
        setError(err.message);
        return [];
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories()
      .then(setCategories)
      .catch(setError);
  }, [cacheTime]);

  return { categories, isLoading, error };
};

export default useProductCategories;
// src/components/TermsAndConditions.js
import React from 'react';
import { Box, Heading, Text, Stack } from '@chakra-ui/react';

const TermsAndConditions = () => {
  return (
    <Box maxW="800px" mx="auto" p="6">
      <Heading as="h1" size="xl" mb="6" textAlign="center">
        Terms and Conditions
      </Heading>
      <Stack spacing="6">
        <Text>
          Welcome to Fashion Editor. These terms and conditions outline the rules and regulations for the use of our website.
        </Text>
        
        <Heading as="h2" size="lg">Introduction</Heading>
        <Text>
          By accessing this website, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you must not use this website.
        </Text>

        <Heading as="h2" size="lg">Intellectual Property Rights</Heading>
        <Text>
          Unless otherwise stated, Fashion Editor owns the intellectual property rights for all material on this website. All intellectual property rights are reserved.
        </Text>

        <Heading as="h2" size="lg">User Obligations</Heading>
        <Text>
          You must not:
          <ul>
            <li>Republish material from this website</li>
            <li>Sell, rent, or sub-license material from this website</li>
            <li>Reproduce, duplicate, or copy material from this website</li>
            <li>Redistribute content from Fashion Editor</li>
          </ul>
        </Text>

        <Heading as="h2" size="lg">Limitation of Liability</Heading>
        <Text>
          Fashion Editor will not be held responsible for any damages arising from the use or inability to use the materials on this website.
        </Text>

        <Heading as="h2" size="lg">Governing Law</Heading>
        <Text>
          These terms and conditions are governed by and construed in accordance with the laws of [Your Country], and you submit to the exclusive jurisdiction of the courts in that state or location.
        </Text>

        <Text>
          For any questions or concerns regarding these terms, please contact us at [Contact Information].
        </Text>
      </Stack>
    </Box>
  );
};

export default TermsAndConditions;
